<template>
  <div id="app">
    <MCTextFormatter />
  </div>
</template>

<script>
import MCTextFormatter from './components/MCTextFormatter.vue';

export default {
  name: 'App',
  components: {
    MCTextFormatter
  }
};
</script>

<style>
/* 可以加入一些全局样式 */
</style>
